























































































































































































































import { defineComponent, ref, PropType, computed, reactive } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import MongoDoc from './types';

export default defineComponent({
  name: 'TableView',
  components: {
    ValidationProvider,
    AgreementAdk: () => import('@/components/molecules/m-default-adk-agreement.vue')
  },
  props: {
    value: {
      required: false,
      type: Object as PropType<MongoDoc>,
      default: () => {}
    },
    adkType: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    updateDoc: {
      type: Function,
      default: () => {}
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    studentId: {
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const HEADER = [
      {
        text: 'Terms',
        align: 'start',
        value: 'terms',
        sortable: false,
        width: '30%'
      },
      {
        text: 'Details',
        align: 'start',
        value: 'questionaries',
        sortable: false,
        width: '55%'
      },
      {
        text: 'I agree',
        align: 'start',
        value: 'complete',
        sortable: false,
        width: '15%'
      }
    ];
    const observer = ref(null);
    const isValid = ref(false);
    const dialog = reactive({
      accept: false,
      decline: false
    });
    const agreementDialog = ref(false);

    const programDoc: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkData = computed(() => {
      return programDoc.value?.data?.adks?.find(a => a.name === props.adkType);
    });
    const fieldIndex = computed(() => {
      return adkData.value?.fields.findIndex(f => f.name === 'm-offer-table');
    });

    const fieldValue = computed({
      get: () => adkData.value?.fields[fieldIndex.value].value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const disabled = computed(() => !!adkData.value?.completed);
    const offerDetails = computed(() => programDoc.value?.data?.offerDetails);
    const tableContents = ref(
      [
        {
          terms: 'Your Project / Task(s)',
          questionaries: `${offerDetails?.value?.internshipProject}`
        },
        {
          terms: 'Position Title',
          questionaries: `I acknowledge my position title will be: ${offerDetails?.value?.position}`
        },
        {
          terms: 'Compensation',
          questionaries: `I acknowledge I will receive the following compensation, custom arrangement or gift: ${offerDetails?.value?.compensation} `
        },
        {
          terms: 'Drivers License / Car / Transport',
          questionaries: `I acknowledge a drivers license, car and/or transport to workplace is ${
            offerDetails?.value?.licenseRequirement ? '' : 'not'
          } required `
        },
        {
          terms: 'Minimum Hours',
          questionaries: `I acknowledge I will work minimum: ${offerDetails?.value?.minHours} hours`
        },
        {
          terms: 'Maximum Hours',
          questionaries: `I acknowledge I will work maximum: ${offerDetails?.value?.maxHours} hours`
        },
        {
          terms: 'Start Date',
          questionaries: `I acknowledge the start date will be: ${offerDetails?.value?.internshipStart}`
        },
        {
          terms: 'Deadline Date',
          questionaries: `I acknowledge the deadline for my work will be: ${offerDetails?.value?.internshipEnd}`
        },
        {
          terms: 'Continuation Possibility',
          questionaries: `I acknowledge there will ${
            offerDetails?.value?.continuation ? '' : 'not'
          } be a continuation or re-hire possibility`
        },
        {
          terms: 'Employer-of-Record',
          questionaries: `I acknowledge PilotCity will ${
            offerDetails?.value?.employerRecord ? '' : 'not'
          } be the employer-of-record`
        }
      ].map(value => ({ ...value, complete: fieldValue.value?.offerStatus }))
    );

    const checkedAllTerms = computed(() => tableContents.value.every((obj: any) => obj.complete));

    function declineOffer() {
      fieldValue.value.offerStatus = false;
      ctx.emit('update-adk');
      dialog.decline = false;
    }

    return {
      header: ref(HEADER),
      declineOffer,
      fieldValue,
      programDoc,
      agreementDialog,
      tableContents,
      checkedAllTerms,
      disabled,
      observer,
      offerDetails,
      adkData,
      dialog,
      isValid
    };
  }
});
