var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.tableContents,"sort-by":"resource","items-per-page":100,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.complete",fn:function(ref){
var item = ref.item;
return [_c('ValidationProvider',{attrs:{"type":"checkbox","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{staticClass:"mx-auto"},[_c('v-checkbox',{attrs:{"error":!!errors.length,"disabled":_vm.disabled},on:{"change":function($event){_vm.isValid = _vm.tableContents.some(function (item) { return item.complete; })}},model:{value:(item.complete),callback:function ($$v) {_vm.$set(item, "complete", $$v)},expression:"item.complete"}}),_c('span',{staticClass:"pt-4 error--text"},[_vm._v(_vm._s(errors.length ? '*' : ''))])],1)]}}],null,true)})]}}])}),_c('div',{staticClass:"d-flex align-center justify-center flex-column mt-6"},[(_vm.adkData && !_vm.adkData.completed)?_c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3 mr-3",attrs:{"disabled":_vm.isMonitor || !!(_vm.fieldValue.agreement && _vm.fieldValue.agreement.minor),"x-large":"","color":"grey","rounded":"","depressed":"","ripple":false,"outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Decline ")])]}}],null,false,791036806),model:{value:(_vm.dialog.decline),callback:function ($$v) {_vm.$set(_vm.dialog, "decline", $$v)},expression:"dialog.decline"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"overline font-weight-bold"},[_vm._v(" Are you sure you want to decline the offer? ")])]),_c('v-divider'),_c('v-container',{staticClass:"justify-center"},[_c('v-row',{staticClass:"justify-center mt-3 mb-5"},[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","x-large":"","rounded":"","depressed":""},on:{"click":function($event){_vm.dialog.decline = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"ma-2 perform-adk-offer-decline",attrs:{"x-large":"","dark":"","loading":_vm.isLoading,"color":"red","rounded":"","depressed":""},on:{"click":_vm.declineOffer}},[_vm._v("Decline Offer")])],1)],1)],1)],1),_c('v-btn',{staticClass:"module-default__row__buttons-accept white--text font-weight-bold",attrs:{"color":"#ae90b0","x-large":"","disabled":!_vm.checkedAllTerms || _vm.isMonitor || !!(_vm.fieldValue.agreement && _vm.fieldValue.agreement.minor),"rounded":"","depressed":""},on:{"click":function($event){_vm.agreementDialog = true}}},[_vm._v(" Sign & Accept ")])],1):_vm._e(),(_vm.adkData.completed || _vm.fieldValue.agreement)?[(_vm.fieldValue.offerStatus)?_c('div',[(
            _vm.fieldValue.agreement && _vm.fieldValue.agreement.minor && !_vm.fieldValue.agreement.guardian
          )?_c('div',[_c('div',{staticClass:"\n              module-default__statement4\n              headline\n              font-weight-medium\n              justify-center\n              mt-5\n              ml-12\n              mr-12\n            ",class:_vm.$vuetify.breakpoint.xs ? 'ml-6 mr-6 mt-6' : ''},[_vm._v(" You have acknowledged the agreement and have sent a text message to your guardian "),(
                _vm.fieldValue.agreement &&
                _vm.fieldValue.agreement.minor &&
                !_vm.fieldValue.agreement.guardian
              )?_c('label',{staticClass:"purple--text"},[_vm._v(" "+_vm._s(_vm.fieldValue.agreement.minor.guardianFirstName)+" "+_vm._s(_vm.fieldValue.agreement.minor.guardianLastName))]):_vm._e(),_c('span'),_vm._v(" to sign the agreement on your behalf and make a linked PilotCity.com account. Please support them directly to sign & make an account via the link provided in the text message. The offer agreement will be automatically accepted once your guardian signs electronically. ")]),_vm._m(1),_c('div',{staticClass:"mt-6 d-flex justify-center align-center"},[_c('h5',[_vm._v("https://pilotcity.com/sign/"+_vm._s(_vm.programDoc.data._id))])])]):_c('div',[_vm._m(2),_c('div',{staticClass:"\n              module-default__statement4\n              headline\n              font-weight-medium\n              justify-center\n              mt-5\n              mb-5\n            ",class:_vm.$vuetify.breakpoint.xs ? 'ml-6 mr-6' : '',staticStyle:{"text-align":"center"}},[_vm._v(" Our internship program will be at a rolling start as we continue to build and release the activities for your internship experience. Feel free to go to the next activities as they release. We appreciate your patience. If you have any questions, feel free to text us at (510) 488-5005. ")])])]):_c('div',[_vm._m(3)])]:_vm._e()],2),(!_vm.isMonitor)?_c('agreement-adk',{attrs:{"is-open":_vm.agreementDialog,"student-doc":_vm.programDoc,"update-doc":_vm.updateDoc},on:{"toggle":function($event){_vm.agreementDialog = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"module-default__overall-statement"},[_c('div',{staticClass:"module-default__secondary-statement"},[_c('span',{staticClass:"module-default__main-statement"},[_vm._v("Congrats!")]),_vm._v(" You’ve won a position with your employer. Please review and agree to the following terms. Accept or decline thereafter. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center mb-6 mt-4 px-12"},[_c('h3',[_vm._v(" If your guardian did not receive the text, please have them go to the link below to sign & make an account. Text (510) 488-5005 with any issues or requests. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n              module-default__statement5\n              headline\n              font-weight-bold\n              mt-12\n              pl-4\n              pr-4\n              justify-center\n            ",staticStyle:{"text-align":"center"}},[_vm._v(" Congratulations on fully signing and accepting your internship offer! "),_c('br')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n            module-default__statement-purple\n            headline\n            font-weight-bold\n            mt-12\n            justify-center\n            pl-10\n            pr-10\n          "},[_vm._v(" We hope you enjoyed the program, and wish for you to participate again! "),_c('br')])}]

export { render, staticRenderFns }